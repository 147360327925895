import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { KeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { ApolloProvider } from '@apollo/client';
import configureStore, { history } from './store/store';
import { addIconLibrary } from './actions/icons';
import { resolveClient } from './graphql/resolver';
import { QueryClient, QueryClientProvider } from 'react-query';
import roles from './constants/roles.json';
import jwt_decode from 'jwt-decode';

import './App.scss';

import keycloak from './config';

const keycloakProviderInitConfig = {
  // onLoad: 'check-sso',
  onLoad: 'login-required',
  checkLoginIframe: false
};

export const { persistor, store } = configureStore();

const loading = () => <div className="animated fadeIn pt-3 text-center d-none"><i className="fas d-none fa-redo fa-spin" /></div>;

const AdminRoute = React.lazy(() => import('./containers/AdminLayout/AdminLayout'));
const Page401 = React.lazy(() => import('./views/Page401/Page401'));
const queryClient = new QueryClient()

addIconLibrary();

function AppRouter({ client }) {
  const [, initialized] = useKeycloak();

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/401" name="Home" render={props => <Page401 {...props} />} />
              <Route path="/" name="Home" render={props => <AdminRoute {...props} />} />
            </Switch>
          </React.Suspense>
        </ConnectedRouter>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

function App() {
  const [client, setClient] = useState(null);

  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={(event, error) => {
        switch (event) {
          case 'onAuthSuccess':
            break;
          case 'onAuthLogout':
            break;
          case 'onTokenExpired':
            keycloak.updateToken(5).then(() => {
              // console.log('successfully get a new token', keycloak.token);
            }).catch(() => {
              history.push('/login');
              keycloak.logout();
            });
            break;
          case 'onInitError':
            break;
          case 'onAuthError':
            setClient(true);
            break;
          default:
            break;
        }
      }}
      onTokens={(tokens) => {
        const auth = jwt_decode(tokens.token);
        localStorage.setItem('access_token', tokens.token);
        axios.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;
        setClient(resolveClient(tokens.token, keycloak));

        if (!auth.realm_access?.roles.includes(roles.GODLIKE)) {
          return history.replace('/401');
        }
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {client && <AppRouter client={client} />}
        </PersistGate>
      </Provider>
    </KeycloakProvider>
  );
}

export default App;

