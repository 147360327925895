import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import networks from './networks';
import auth from './auth';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  networks,
  auth
});

export default createRootReducer;
